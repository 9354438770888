

























import { Component } from 'vue-property-decorator';
import { BaseView } from '@shared/views/BaseView';
import { Category, CategorySearchRequest, PagedResult, SubjectAggregate } from '@shared/models';

@Component({})
export default class CollectionHomne extends BaseView {

	public featured: PagedResult<Category> = new PagedResult<Category>();
	public request: CategorySearchRequest = new CategorySearchRequest();

	/** Fires when this component is loaded */
	public async mounted() {
		this.loading = true;
		try {
			this.request.isFeatured = true;
			this.featured = await this.$api.categoryService.search(this.request);
		} catch (err) {
			this.errorHandler.handle(err, 'An error occurred while loading collections');
		} finally {
			this.loading = false;
		}
	}

}

